
#CanvasArea{
    width : 480px;
    min-height: 480px;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

#CanvasArea > #CanvasHeader{
    width : 100%;
    height: 50px;
    background-color: gray;
    border-radius: 10px 10px 0 0;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

#CanvasArea > #CanvasHeader > #CanvasHeaderMessage{
    position: absolute;
}

#CanvasArea > #CanvasBody{
    width : 100%;
    height: 480px;
    border: 1px solid black;
    position: relative;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup{
    width: 100%;
    height: 480px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .Line{
    position: absolute;
    background-color: black;
    width: fit-content;
    height: fit-content;
    z-index: 1;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .Line.Horizontal{
    height: 2px;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .Line.Vertical{
    width: 2px;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle{
    width: 160px;
    height: 160px;
    position: absolute;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .CanvasElement{
    width : 100%;
    height : 100%;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject{
    width : 100%;
    height : 100%;
    position: absolute;
    cursor: pointer;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow{
    width : 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow > span{
    position: absolute;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow > span:hover{
    color: red;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow > .East{
    right: 0;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow > .West{
    left: 0;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow > .South{
    bottom: 0;
}
#CanvasArea > #CanvasBody > #CanvasPuzzleGroup > .CanvasPuzzle > .ArrowObject > .Arrow > .North{
    top: 0;
}


#CanvasArea > #CanvasFooter {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    border: 1px solid black;
    width: 100%;
}